import React from 'react';
import './App.css';
import bgVideo from "./rsc/app-bg.mp4"
import bgVideoOGG from "./rsc/app-bg.ogg";
import bgVideo2 from './rsc/ominis-background-1080p.mp4'
import sha256 from 'crypto-js/sha256';

function App() {
    const urlPath = window.location.pathname;
    console.log(urlPath);
    console.log(sha256(urlPath).toString());
    if (sha256(urlPath).toString() === '6b01b16961cd78f8ad711030216157d40fa2514d84f092f9e7fba932fb872ea6') {
        return (
            <div className="App">
                <video className='background-video-2' muted={true} autoPlay={true} loop={true} playsInline={true}>
                    <source src={bgVideo2} type="video/mp4"/>
                </video>
                <div className='centerBox'>
                    <div className='title'><span className='firstLetter'>M</span><span>ALONE'S </span><span
                        className='firstLetter'>M</span><span>ERCANTILE</span></div>
                </div>
                <div className='centerBox2'>
                    <div className='subtitle'>SECRET PREVIEW</div>
                    <div className='normal'>for</div>
                    <div className='normal'>FRIENDS & FAMILY</div>
                </div>
                <div className='centerBox3'>
                    <div className='normal'>USE PASSWORD: "open-soap-a-me"</div>
                    <a href="https://malonesmercantile.com" target="_blank" rel="noopener noreferrer">
                        <button>GO</button>
                    </a>
                </div>
            </div>
        );
    } else {
        return (
            <div className="App">
                <video className='background-video' muted={true} autoPlay={true} loop={true} playsInline={true}>
                    <source src={bgVideo} type="video/mp4"/>
                    <source src={bgVideoOGG} type="video/ogg"/>
                </video>
                <div className='centerBox'>
                </div>
                <div className='centerBox2'>
                    <div className='title'><span className='firstLetter'>M</span><span>ALONE</span></div>
                    <div className='subtitle'>&nbsp;CONSULTING</div>
                </div>
                <div className='centerBox3'>
                    <form className='login-form'>
                        <input type='text' name='username' placeholder='username'/>
                        <input type='password' name='password' placeholder='password'/>
                    </form>
                </div>
            </div>
        );
    }
}

export default App;
